import React, { useState, useEffect } from 'react';
import './App.css';
import { BsSuitDiamondFill } from "react-icons/bs";
import { FaLock, FaLockOpen } from 'react-icons/fa'
import eventData from './json/events.json'

function App() {

  const startingBankroll = 3000;

  const [counter, setCounter] = useState(eventData.map(() => 0));
  const [bankroll, setBankroll] = useState(startingBankroll);
  const [profitLoss, setProfitLoss] = useState(0);
  const [popup, setPopup] = useState(false);
  const [popupValue, setPopupValue] = useState('');
  const [popupIndex, setPopupIndex] = useState(null);
  const [eventStat, setEventStat] = useState(eventData.map(() => 0));
  const [lock, setLock] = useState(eventData.map(() => false));

  useEffect(() => {
    const savedCounter = JSON.parse(localStorage.getItem('counter'));
    const savedBankroll = JSON.parse(localStorage.getItem('bankroll'));
    const savedProfitLoss = JSON.parse(localStorage.getItem('profitLoss'));
    const savedEventStat = JSON.parse(localStorage.getItem('eventStat'));

    if (savedCounter && Array.isArray(savedCounter) && savedCounter.length === eventData.length) {
      setCounter(savedCounter);
    }
    if (typeof savedBankroll === 'number') {
      setBankroll(savedBankroll);
    }
    if (typeof savedProfitLoss === 'number') {
      setProfitLoss(savedProfitLoss);
    }
    if (savedEventStat && Array.isArray(savedEventStat) && savedEventStat.length === eventData.length) {
      setEventStat(savedEventStat);
    }

  }, []);

  useEffect(() => {
    if (counter.length === eventData.length && bankroll !== startingBankroll) {

      localStorage.setItem('counter', JSON.stringify(counter));
      localStorage.setItem('bankroll', JSON.stringify(bankroll));
      localStorage.setItem('profitLoss', JSON.stringify(profitLoss));
      localStorage.setItem('eventStat', JSON.stringify(eventStat));
    }
  }, [counter, bankroll, profitLoss, eventStat]);


  const addBuyin = (event, index) => {
       const buyin = Number(event['Buy in'])
      setCounter(counter.map((counter, i) => (i === index ? counter + 1 : counter)));
      const updateBankroll = bankroll - buyin;
      setBankroll(updateBankroll)
      const updateProfitLoss = updateBankroll - startingBankroll;
      setProfitLoss(updateProfitLoss)
      setEventStat(eventStat.map((pl, i) => (i === index ? pl - buyin : pl)));
  }

  const removeBuyin = (event, index) => {
    if (counter[index] > 0) {
      const buyin = Number(event['Buy in'])
      setCounter(counter.map((counter, i) => (i === index ? counter - 1 : counter)));
      const updateBankroll = bankroll + buyin;
      setBankroll(updateBankroll)
      const updateProfitLoss = updateBankroll - startingBankroll;
      setProfitLoss(updateProfitLoss)
      setEventStat(eventStat.map((pl, i) => (i === index ? pl + buyin : pl)));
    }
    
}

  const openPopup = (index) => {
    setPopupIndex(index);
    setPopup(true);
  }

  const handlePopup = () => {
    const updateBankroll = bankroll + Number(popupValue);
    setBankroll(updateBankroll);
    const updateProfitLoss = updateBankroll - startingBankroll;
    setProfitLoss(updateProfitLoss);

    if (popupIndex !== null) {
      setEventStat(eventStat.map((pl, i) => (i === popupIndex ? pl + Number(popupValue) : pl)));
    }

    setPopupValue('')
    setPopup(false);
  }

  const toggleLock = (index) => {
    setLock(lock.map((locked, i) => (i === index ? !locked : locked)));
  }

    // RESET DATA
  const resetData = () => {
    localStorage.removeItem('counter');
    localStorage.removeItem('bankroll');
    localStorage.removeItem('profitLoss');
    localStorage.removeItem('eventStat');

    setCounter(eventData.map(() => 0));
    setBankroll(startingBankroll);
    setProfitLoss(0);
    setEventStat(eventData.map(() => 0));

    console.log('Reset local storage and state to initial values');
  }

  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-US', {
      style: 'currency', 
      currency: 'USD'
    })
  }



  return (
    <main className="main-container">
      <div className='heading'>
        <BsSuitDiamondFill className='header-icon' />
        <h1>Kings Series</h1>
        <BsSuitDiamondFill className='header-icon' />
      </div>

      <section className='bankroll-details'>
        <div className='bankroll-info-card'>
          <p className='bankroll-info-heading'>Profit / Loss</p>
          <p className='bankroll-info-deatils'>{formatCurrency(profitLoss)}</p>
        </div>

        <div className='bankroll-info-card'>
          <p className='bankroll-info-heading'>Bankroll</p>
          <p className='bankroll-info-deatils'>{formatCurrency(bankroll)}</p>
        </div>
      </section>

      <section className='eventCard-container'>
        {eventData.map((event, index) => {
            const cardStyle = {
              background: event.Dealer === 'Yes' ? 'linear-gradient(to right, #ba8b02, #181818)' : 'linear-gradient(to right, #e9564b, #d21a2d)'
            };

            return (
          <div className='eventCard' key={index} style={cardStyle}>
               {lock[index] ? ( 
                <div>
                  <div className='eventCardTop' style={{paddingTop: '1rem'}}>
                    <button className='lock' onClick={() => toggleLock(index)}>
                      {lock[index] ? <FaLockOpen className='lock-icon' /> : <FaLock className='lock-icon' /> }
                    </button>
                    <p className='eventCardTop-EventTitle'>{event['Event']}</p>
                    <p className='eventCardTop-Buyin'>${formatCurrency(Number(event['Buy in']).toFixed(0))}</p>
                    <p>{counter[index]}</p>
                  </div>

                  {counter[index] > 0 && (
                    <p className='eventcardProf'>Profit / Loss: <span>{formatCurrency(eventStat[index])}</span></p>
                  )}
                </div>
              ) : (
                <div>
                  <div className='eventCard-header'>
                  <p>{event['Day']}</p>
                  <p>{event['Date']}</p>
                  <p>{event['Start Time']}</p>
                  <p className='eventCard-Rego'> Level {event['Late Rego']}</p>
                  <p>({event['myBuyIn']})</p>
                </div>
                <div className='eventCardTop'>
                  <button className='lock' onClick={() => toggleLock(index)}>
                    {lock[index] ? <FaLockOpen className='lock-icon'/> : <FaLock className='lock-icon' /> }
                  </button>
                  <p className='eventCardTop-EventTitle'>{event['Event']}</p>
                  <p className='eventCardTop-Buyin'>${formatCurrency(Number(event['Buy in']).toFixed(0))}</p>
                  <div className='eventCardBtns'>
                  <button onClick={() => addBuyin(event, index)}>+</button>
                  <button onClick={() => removeBuyin(event, index)}>-</button>
                  <button className='eventCard-Payout-btn' onClick={() => openPopup(index)}>$</button>
                  <p>{counter[index]}</p>
                  </div>
                </div>
                <div className='eventCardBottom'>
                  <div className='eventCardBottom-Labels'>
                    <p>Stack</p>
                    <p>{event['chips']}</p>
                  </div>
                  <div className='eventCardBottom-Labels'>
                    <p>Blinds</p>
                    <p>{event['blinds']} mins</p>
                  </div>
                  <div className='eventCardBottom-Labels'>
                    <p>Re-Entries</p>
                    <p>{event['Re-enties']}</p>
                  </div>
                  </div>
                  {counter[index] > 0 && (
                    <p className='eventcardProf'>Profit / Loss: <span>{formatCurrency(eventStat[index])}</span></p>
                  )}
                </div>
              )}
            
          </div>
            );
        })}
      </section>
      {popup && (
        <div className='popup-container'>
          <div className='popup'>
            <h3>Winner Winner!</h3>
            <input 
              type='number'
              value={popupValue}
              onChange={(e) => setPopupValue(e.target.value)}
              placeholder='Enter Winnings...'
            />
            <button className='popup-update' onClick={handlePopup}>Update</button>
            <button className='popup-close' onClick={() => {
              setPopup(false);
              setPopupValue('');
            }}>Close</button>
          </div>
        </div>
  )}
    {/* Reset Button */}
    {/* <button className='reset-button' onClick={resetData}>Reset Data</button> */}

    </main>
   
  );
}

export default App;
